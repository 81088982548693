<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :multiple="!singleSelect"
        :data="invoices"
        :sst="true"
        @search="search"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <!-- Actions

      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
          <span class="mr-2"> Action </span>
          <feather-icon
              icon="ChevronDownIcon"
              svgClasses="h-4 w-4"
          />
        </div>
        <vs-dropdown-menu>

          <vs-dropdown-item>
            <span>Create</span>
          </vs-dropdown-item>

        </vs-dropdown-menu>
      </vs-dropdown>-->

            <!-- STATUS -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ status }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="setLimit('All', 'all')">
                        <span>All </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('Users', 'user')"
                    >
                        <span>Users</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="setLimit('Brokers', 'broker')">
                        <span>Brokers</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('Created', 'created')"
                    >
                        <span>Created</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="setLimit('Submitted', 'submitted')"
                    >
                        <span>Submitted</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="setLimit('Confirmed', 'confirmed')"
                    >
                        <span>Confirmed</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="setLimit('Cancelled', 'cancelled')"
                    >
                        <span>Cancelled</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="setLimit('Failed', 'failed')">
                        <span>Failed</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="setLimit('Paid Out', 'paid_out')">
                        <span>Paid Out</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{ itemsPerPage }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>User/Broker</vs-th>
            <vs-th>Date</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Amount</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        <span
                            @click="handleSelected(data[index])"
                            v-if="data[index].user && data[index].user.details"
                            class="link"
                            >{{ data[index].user.details.full_name }}</span
                        >
                        <span
                            v-else-if="data[index].broker"
                            @click="handleSelected(data[index])"
                            class="link"
                            >{{ data[index].broker.company_name }}</span
                        >
                    </vs-td>

                    <vs-td
                        ><span v-if="data[index].date">{{
                            formatDateAndTime(data[index].date)
                        }}</span></vs-td
                    >

                    <vs-td>
                        <vs-chip v-if="data[index].created">Created</vs-chip>
                        <vs-chip v-if="data[index].submitted"
                            >Submitted</vs-chip
                        >
                        <vs-chip v-if="data[index].confirmed" color="primary"
                            >Confirmed</vs-chip
                        >
                        <vs-chip v-if="data[index].cancelled" color="secondary"
                            >Cancelled</vs-chip
                        >
                        <vs-chip v-if="data[index].failed" color="secondary"
                            >Failed</vs-chip
                        >
                        <vs-chip v-if="data[index].paid_out" color="success"
                            >Paid Out</vs-chip
                        >
                    </vs-td>

                    <vs-td>
                        <span v-if="data[index].printing_amount"
                            >Printing Amount £{{
                                data[index].printing_amount / 100
                            }}<br
                        /></span>
                        <span v-if="data[index].invoice_amount"
                            >Invoice Amount £{{
                                data[index].invoice_amount / 100
                            }}<br
                        /></span>
                        <span v-if="data[index].subscription_amount"
                            >Subscription Amount £{{
                                data[index].subscription_amount / 100
                            }}<br
                        /></span>
                        <span v-if="data[index].will_amount"
                            >Will Amount £{{ data[index].will_amount / 100
                            }}<br
                        /></span>
                        <span v-if="data[index].go_cardless_payment_id"
                            >GC Payment ID
                            {{ data[index].go_cardless_payment_id }}<br
                        /></span>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'PaymentsAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage', 'dataSelected', 'limit'],
    computed: {
        invoices() {
            if (this.data) return this.data.data
            return null
        },
        selected: {
            set(value) {
                this.$emit('selected', value)
            },
            get() {
                return this.dataSelected
            },
        },
    },
    data() {
        return {
            searchQuery: '',
            status: 'All',
            singleSelect: true,
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        handleSelected(event) {
            if (event.user) {
                this.$router.push({
                    name: 'user',
                    params: { id: event.user.id },
                })
            } else if (event.broker) {
                this.$router.push({
                    name: 'broker',
                    params: { pk: event.broker.email },
                })
            }
        },
    },
}
</script>
